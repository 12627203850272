// BlogPage.js
import React from 'react';

const BlogSchema = () => {
  return (
    <div>
      {/* Your React component content for the personal finance blog */}
      {/* ... */}

      {/* Structured Data */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Blog",
            "name": "Money on Hard Mode: A blog cataloging the journey to financial freedom in a very high cost of living city",
            "url": "https://www.assetcalculators.com/blog",
            "publisher": {
              "@type": "Organization",
              "name": "Asset Calculators"
            }
          }
        `}
      </script>
    </div>
  );
};

export default BlogSchema;