import { Button } from 'react-bootstrap';
import '../style.css';
import Form from 'react-bootstrap/Form';
import React, {useState} from 'react';

const MailchimpForm = () => {

    const [email, setEmail] = useState('');

    const handleEmailChange = (e) => {
      setEmail(e.target.value);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      // Now you can use the 'email' state to send the form data or perform any other actions
      console.log('Submitted email:', email);
  
      // Add your logic to send the form data to Mailchimp or any other service
    };

    return (
      <div id="mc_embed_shell">
        <link
          href="//cdn-images.mailchimp.com/embedcode/classic-061523.css"
          rel="stylesheet"
          type="text/css"
        />
        <style>
          {`
            #mc_embed_signup {
              clear: left;
              font: 14px Helvetica, Arial, sans-serif;
              width: 300px;
            }
            /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
            We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
          `}
        </style>
        <div id="mc_embed_signup">
          <form
            action="https://icloud.us13.list-manage.com/subscribe/post?u=eae6a86a49c2c52770e24b729&amp;id=a5b2d901bd&amp;f_id=00a80be4f0"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
          >
            <div id="mc_embed_signup_scroll">
              <div className="mc-field-group">
                <label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span></label>
                <input
                  type="email"
                  name="EMAIL"
                  className="required email"
                  id="mce-EMAIL"
                  required=""
                  value={email}
                  onChange={handleEmailChange}
                />
                <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
              </div>
              <div id="mce-responses" className="clear foot">
                <div id="mce-error-response" style={{ display: 'none' }}></div>
                <div id="mce-success-response" style={{ display: 'none' }}></div>
              </div>
              <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
                {/* Real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                <input type="text" name="b_eae6a86a49c2c52770e24b729_a5b2d901bd" tabIndex="-1" value="" />
              </div>
              <div className="optionalParent">
                <div className="clear foot">
                  <input
                    type="submit"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    className="button"
                    value="Subscribe"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script>
        <script type="text/javascript">
          {`
            (function($) {
              window.fnames = new Array();
              window.ftypes = new Array();
              fnames[0]='EMAIL';
              ftypes[0]='email';
              fnames[1]='FNAME';
              ftypes[1]='text';
              fnames[2]='LNAME';
              ftypes[2]='text';
              fnames[3]='ADDRESS';
              ftypes[3]='address';
              fnames[4]='PHONE';
              ftypes[4]='phone';
              fnames[5]='BIRTHDAY';
              ftypes[5]='birthday';
            }(jQuery));
            var $mcj = jQuery.noConflict(true);
          `}
        </script>
      </div>
    );
  };

/* 
function emailForm() {
    return (
      <Form
        action="mailto:oxtails.eminent_0y@icloud.com"
        method="POST"
        encType="text/plain"
        name="emailForm"
        >
        <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="name@example.com" name = "email"/>
            <Form.Text className="text-muted">
                We'll never email you with spam or share your email with anyone else.
            </Form.Text>
        </Form.Group>
        <Button variant="primary" type="submit">
            Submit
        </Button>
      </Form>
    );
  } */

export default function Contact() {
    return (
        <div>
            <div className = "pageHeader display-4">
                Money on Hard Mode
            </div>
            <div className = "pageContent">
                <p>
                    We're hard at work trying to build the next tool to help you with your financial freedom but we're also planning to launch
                    our blog, Money on Hard Mode, that dives into why these tools are useful. It's written from the perspective of two mid-thirty year olds trying to survive in Vancouver, BC.
                    Amidst housing costs that are 10x the median income and lower salaries than our neighbours to the south, trying to retire in this very high cost
                    of living city can feel like playing on hard mode. But we're here to document the journey!
                </p>
                <p>
                    We're not quite ready to launch yet, but if you want to stay in touch for updates, sign up with your email below
                </p>
            </div>
            <div className = "emailForm">
                {MailchimpForm()}
            </div>
        </div>
    )
}