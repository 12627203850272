import React from 'react';

const PortfolioSchema = () => {
  return (
    <div>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "SoftwareApplication",
            "name": "Portfolio Rebalancing Calculator",
            "applicationCategory": "Finance",
            "description": "A tool to help users rebalance their porfolios to maintain a consistent asset allocation mix.",
            "operatingSystem": "Web",
            "url": "https://www.assetcalculators.com/",
            "breadcrumb": {
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Home",
                  "item": "https://www.assetcalculators.com/home"
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "name": "Portfolio Calculator",
                  "item": "https://www.assetcalculators.com/blog"
                }
              ]
            }
          }
        `}
      </script>
    </div>
  );
};

export default PortfolioSchema;