import ApexCharts from 'apexcharts'
import Chart from 'apexcharts'
import React, { Component } from 'react';

function Pie() {

    const options = { labels: ["Comedy", "Action", "Romance", "Drama", "SciFi"] };
    const series = [4, 5, 6, 1, 5]; //our data

    return(
        <Chart
            options={options}
            series={series}
            type="pie"
            width="450"
        />
    )
}


export default Pie;
