import './App.css';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import React, { useState, useRef, Component } from 'react';
import Home from "./home"
import Blog from "./pages/blog"
import NavBar from "./navBar"
import PortfolioSchema from './pages/portfolioSchema'; 
import BlogSchema from './pages/blogSchema'; 



function App() {
  let Component;
  let SchemaMarkupComponent;


  switch(window.location.pathname) {
    case "/":
      Component = Home;
      SchemaMarkupComponent = PortfolioSchema;
      break
    case "/blog":
      Component = Blog;
      SchemaMarkupComponent = BlogSchema;
      break
  }
  return (
    <>
      <NavBar />
      <SchemaMarkupComponent />
      <Component />
      {/* <Modal /> */}
    </>
  )
}

export default App

  